import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Badge,
  IconButton,
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  CircularProgress,
  Paper,
  Chip
} from '@mui/material';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { styled } from '@mui/material/styles';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';
import ArgonEditor from 'components/ArgonEditor';
import ArgonInput from 'components/ArgonInput';
import {
  getConversations,
  // getConversationById nicht mehr benötigt, da wir die Daten bereits haben
  updateConversationStatus,
  sendResponse
} from 'services/REST_API/conversations';
import ArgonSelect from 'components/ArgonSelect';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import SmsIcon from '@mui/icons-material/Sms';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import EventIcon from '@mui/icons-material/Event';
import ConversationMessages from './ConversationMessages';
import HorizontalDivider from 'components/HorizontalDivider';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { FormControlLabel, Switch, InputAdornment } from '@mui/material';
import {
  queryKnowledgeBase,
  queryKnowledgeBaseStream
} from 'services/REST_API/ai/kb-request';
import { useLazyQuery } from '@apollo/client';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import selectData from 'options/selectData';
import { extractTextFromHtml } from 'services/RenderSafeHtml';

import useResizeObserver from 'use-resize-observer';
import { useRef } from 'react';
import StreamingQueryComponent from './StreamedResponsesTest';
import QuillEditorFlat from 'components/QuillEditorFlat';
import { markConversationAsRead } from 'services/REST_API/conversations';

// Styled components
const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  borderLeft: selected
    ? `4px solid ${theme.palette.primary.main}`
    : '4px solid transparent',
  backgroundColor: selected ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  }
}));

// MessageBubble wurde in ConversationMessages.js verschoben

// Helper function to get channel icon
const getChannelIcon = (channel) => {
  switch (channel?.toLowerCase()) {
    case 'email':
      return <EmailIcon />;
    case 'whatsapp':
      return <WhatsAppIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'sms':
      return <SmsIcon />;
    default:
      return <HelpOutlineIcon />;
  }
};

// Helper function to format date (nur für die Konversationsliste)
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};

function Conversations({
  organizationId
  // onConversationSelected,
  // selectedEventInstance,
  // eventInstanceLoading
}) {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversationDetails, setConversationDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseType, setResponseType] = useState('external'); // 'external' or 'internal'
  const [responseContent, setResponseContent] = useState('');
  const [sendingResponse, setSendingResponse] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [filter, setFilter] = useState({
    isRead: undefined,
    channel: ''
  });

  const [eventInstance, setEventInstance] = useState(null);
  const [aiQueryLoading, setAiQueryLoading] = useState(false);
  const [streamConnection, setStreamConnection] = useState(null);

  // observer size change of editor pane to adjust editor height
  const paneRef = useRef(null);
  // Direkter Zugriff auf den Quill-Editor
  const editorRef = useRef(null);

  const { width: paneWidth, height: paneHeight } = useResizeObserver({
    ref: paneRef
  });

  // Diese Funktion wird nicht mehr benötigt, da wir forwardRef verwenden
  // const handleEditorRef = (ref) => {
  //   console.log('Editor Ref erhalten:', ref);
  //   editorRef.current = ref;
  // };

  // Apollo-Query für EventInstance
  const [
    getEventInstance,
    { data: eventInstanceData, loading: eventInstanceLoading }
  ] = useLazyQuery(EVENTINSTANCE_GET_BY_ID, {
    // onCompleted: (data) => {
    //   console.log('eventInstanceData:', data);
    //   setEventInstance(data.eventInstance);
    // },
    // variables: { eventInstanceId: selectedConversation?.eventInstanceId },
    // skip: !selectedConversation?.eventInstanceId,
    fetchPolicy: 'cache-first'
  });

  // Fetch conversations
  const fetchConversations = async () => {
    if (!organizationId) return;

    setLoading(true);
    try {
      const params = {
        organizationId,
        ...filter
      };

      const result = await getConversations(params);
      setConversations(result.conversations || []);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle conversation selection
  const handleSelectConversation = async (conversation) => {
    console.log('Conversation selected:', conversation._id);

    // Schließe laufende AI-Stream-Verbindung, falls vorhanden
    console.log(
      'Checking for active stream connection before selecting new conversation'
    );
    closeStreamConnection();

    setSelectedConversation(conversation);
    await markConversationAsRead({
      conversationId: conversation._id
    });
    await fetchConversations(); // refresh the conversation list

    // Anstatt die Konversation erneut zu laden, verwenden wir die bereits vorhandenen Daten
    setConversationDetails(conversation);

    // Editor-Inhalt beim Wechsel der Konversation zurücksetzen
    setResponseContent('');

    // EventInstance laden
    console.log(
      'Loading event instance for conversation:',
      conversation.eventInstanceId
    );
    getEventInstance({
      variables: { eventInstanceId: conversation.eventInstanceId }
    })
      .then((result) => {
        console.log(
          'eventInstanceData for registration:',
          result.data.eventInstance
        );
        setEventInstance(result.data.eventInstance);
      })
      .catch((error) => {
        console.error('Error loading event instance:', error);
      });

    // // Callback-Funktion aufrufen, um die übergeordnete Komponente über die Auswahl zu informieren
    // if (onConversationSelected) {
    //   onConversationSelected(conversation);
    // }
  };

  // Schließt eine vorhandene Stream-Verbindung
  const closeStreamConnection = () => {
    if (streamConnection) {
      console.log('Closing existing stream connection');
      try {
        console.log(
          'Stream connection object before closing:',
          streamConnection
        );
        streamConnection.close();
        console.log('Stream connection closed successfully');
      } catch (error) {
        console.error('Error closing stream connection:', error);
      } finally {
        console.log('Setting streamConnection to null');
        setStreamConnection(null);
      }
    } else {
      console.log('No stream connection to close');
    }
  };

  // Komponente schließt alle Stream-Verbindungen beim Unmounten
  useEffect(() => {
    console.log('Setting up cleanup effect for stream connections');
    return () => {
      console.log('Component unmounting, closing stream connection');
      closeStreamConnection();
    };
  }, []);

  const handleComposeMessageWithAiButtonPressed = () => {
    console.log('handleComposeMessageWithAiButtonPressed');

    if (!selectedConversation) {
      console.log('No conversation selected');
      return;
    }

    console.log(
      'Handle Compose With AI: selected messages:',
      selectedConversation
    );
    console.log('eventInstance:', eventInstance);

    if (
      !eventInstance?.aiAssistantApiKey ||
      !eventInstance?.aiAssistantClientId
    ) {
      console.log('No AI Assistant API key or client ID found');
      return;
    }

    // Vorherige Verbindung schließen, falls vorhanden
    console.log(
      'Closing any existing stream connection before starting a new one'
    );
    closeStreamConnection();

    // Editor-Inhalt zurücksetzen
    setResponseContent('');

    // Nachrichten für die AI vorbereiten
    const messages = selectedConversation.messages.map((message) => {
      return {
        direction: message.direction,
        content: extractTextFromHtml(message.content),
        createdAt: message.createdAt
      };
    });

    console.log('messages prepared to be sent to AI:', messages);

    // Lade-Status aktivieren
    setAiQueryLoading(true);

    // Nutzung des bereits auf Komponentenebene definierten editorRef
    console.log(
      'Verwende editorRef aus dem Komponenten-Scope:',
      editorRef.current
    );

    // Debug-Informationen zum API-Key
    console.log(
      'Using API Key (first 5 chars):',
      eventInstance.aiAssistantApiKey
        ? eventInstance.aiAssistantApiKey.substring(0, 5) + '...'
        : 'undefined'
    );

    // Verbesserte Funktion zum Erkennen und Verarbeiten von kompletten HTML-Fragmenten
    const processHtmlBuffer = (buffer) => {
      // Wenn der Buffer leer ist, direkt zurückgeben
      if (!buffer || buffer.length === 0) {
        return { processedHtml: '', remainingBuffer: '' };
      }

      // Einfache Strategie: Wenn ein < ohne entsprechendes > am Ende steht,
      // nehmen wir an, dass es ein unvollständiger Tag ist
      const lastLessThanIndex = buffer.lastIndexOf('<');

      if (
        lastLessThanIndex !== -1 &&
        buffer.indexOf('>', lastLessThanIndex) === -1
      ) {
        // Unvollständiger Tag gefunden - alles bis zum letzten < ist verarbeitbar
        const processed = buffer.substring(0, lastLessThanIndex);
        const remaining = buffer.substring(lastLessThanIndex);

        console.log(
          'Buffer-Verarbeitung: Unvollständiger Tag erkannt. Verarbeite bis Index:',
          lastLessThanIndex,
          'Verarbeiteter Inhalt:',
          processed.length,
          'Bytes, Verbleibender Buffer:',
          remaining.length,
          'Bytes'
        );

        return { processedHtml: processed, remainingBuffer: remaining };
      }

      // Alle Tags scheinen vollständig zu sein
      return { processedHtml: buffer, remainingBuffer: '' };
    };

    // HTML-Buffer für die Tag-Verarbeitung
    let htmlBuffer = '';

    // Sammelnden Inhalt für den gesamten Stream
    let completeResponse = '';

    console.log('Initiating stream connection to AI service');

    // Stream-Verbindung öffnen
    try {
      const connection = queryKnowledgeBaseStream({
        apiKey: eventInstance.aiAssistantApiKey,
        query: JSON.stringify(messages),
        queryPrefix:
          "Here's an array of messages; property direction states whether the message was sent from the user to us (incoming) or we sent a message to the athlete (outgoing). You will answer to the latest inquiry of the athlete, taking into account previously exchanged messages, in the form of a well formatted text message. When the message format received is html it was an email and you style your response as en email. You will also use html with common formatting options where appropriate, e.g. paragraphs, lists, bold, italic, underline, strike, etc.; salutation and closing formula is separated from the message contents by an empty row (<br />). You will answer in the same language as the athlete wrote you. The answer will be composed in basic html. Add empty row (by adding <br />) between paragraphs to make it more readable for human eyes. Do not refer to the documents when there is no clear information available for the inquiry. Only refer to documents when you can name them.",

        // Callback für eingehende Inhaltstokens - mit HTML-Buffer-Verarbeitung
        onContent: (content) => {
          console.log('Received token in Conversations component:', content);

          // Füge den neuen Chunk zum HTML-Buffer hinzu
          htmlBuffer += content;

          // Verarbeite den Buffer, um unvollständige HTML-Tags zu erkennen
          const { processedHtml, remainingBuffer } =
            processHtmlBuffer(htmlBuffer);

          // Aktualisiere den Buffer mit den verbleibenden (unvollständigen) Daten
          htmlBuffer = remainingBuffer;

          // Wenn verarbeitbare HTML-Daten vorhanden sind, füge sie zur Gesamtantwort hinzu
          if (processedHtml) {
            completeResponse += processedHtml;

            // Aktualisiere nur den React-State, nicht den Editor direkt
            // Der Editor wird durch den React-Datenfluss automatisch aktualisiert
            setResponseContent(completeResponse);

            console.log(
              'Aktualisiere Response-Content mit verarbeitetem HTML, neue Länge:',
              completeResponse.length,
              'Bytes, verbleibender Buffer:',
              htmlBuffer.length,
              'Bytes'
            );
          }
        },

        // Optionaler Callback für Quelleninformationen
        onSources: (sources) => {
          console.log('Received sources:', sources);
          // Hier könnten Quellenangaben angezeigt werden, falls benötigt
        },

        // Callback für Stream-Ende
        onEnd: () => {
          console.log('AI stream completed');

          // Verarbeite verbliebene Daten im HTML-Buffer, falls vorhanden
          if (htmlBuffer && htmlBuffer.length > 0) {
            console.log(
              'Verbleibende Daten im Buffer beim Stream-Ende:',
              htmlBuffer
            );
            // Am Stream-Ende fügen wir auch unvollständige Tags hinzu,
            // da keine weiteren Daten mehr kommen werden
            completeResponse += htmlBuffer;
            setResponseContent(completeResponse);
            console.log('Restlicher Buffer zur Gesamtantwort hinzugefügt');
            htmlBuffer = '';
          }

          setAiQueryLoading(false);
          setStreamConnection(null);
        },

        // Callback für Fehler
        onError: (error) => {
          console.error('Error in AI stream:', error);
          setAiQueryLoading(false);
          setStreamConnection(null);
        }
      });

      console.log('Stream connection established, saving connection object');

      // Stream-Verbindung speichern, um sie später schließen zu können
      setStreamConnection(connection);
    } catch (error) {
      console.error('Failed to establish stream connection:', error);
      setAiQueryLoading(false);
    }
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    // Update filter based on tab
    let newFilter = { ...filter };

    switch (newValue) {
      case 0: // All
        newFilter.isRead = undefined;
        break;
      case 1: // Unread
        newFilter.isRead = false;
        break;
      case 2: // Read
        newFilter.isRead = true;
        break;
      default:
        break;
    }

    setFilter(newFilter);
  };

  // Handle response type change
  const handleResponseTypeChange = (selectedOption) => {
    setResponseType(selectedOption.value);
  };

  // Handle send response
  const handleSendResponse = async () => {
    if (!selectedConversation || !responseContent.trim()) {
      console.log(
        'Cannot send response: No conversation selected or empty response content'
      );
      return;
    }

    // Schließe laufende AI-Stream-Verbindung, falls vorhanden
    console.log(
      'Checking for active stream connection before sending response'
    );
    closeStreamConnection();

    setSendingResponse(true);
    try {
      console.log(
        'Sending response to conversation:',
        selectedConversation._id
      );
      await sendResponse({
        conversationId: selectedConversation._id,
        content: responseContent,
        format: 'html',
        // If it's an internal note, we would handle it differently
        // This is a placeholder for the internal notes functionality
        isInternal: responseType === 'internal'
      });

      console.log('Response sent successfully');

      // Clear response content
      setResponseContent('');

      // Konversationsliste neu laden und dann die ausgewählte Konversation aktualisieren
      console.log('Refreshing conversations list after sending response');
      fetchConversations()
        .then(async () => {
          // Nach dem Neuladen der Konversationen die ausgewählte Konversation aktualisieren
          const updatedConversation = conversations.find(
            (conv) => conv._id === selectedConversation._id
          );
          if (updatedConversation) {
            console.log('Updating selected conversation with refreshed data');
            setSelectedConversation(updatedConversation);
            setConversationDetails(updatedConversation);
          } else {
            console.log(
              'Could not find updated conversation in refreshed list'
            );
          }
        })
        .catch((error) => {
          console.error('Error refreshing conversations:', error);
        });
    } catch (error) {
      console.error('Error sending response:', error);
    } finally {
      setSendingResponse(false);
    }
  };

  // Effect to fetch conversations when organizationId or filter changes
  useEffect(() => {
    fetchConversations();
  }, [organizationId, filter]);

  return (
    <ArgonBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card
            sx={{
              height: '75vh',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden'
            }}>
            <Allotment style={{ height: '100%' }}>
              {/* Conversations List */}
              <Allotment.Pane
                preferredSize={300}
                minSize={250}
                style={{ overflow: 'hidden', height: '100%' }}>
                <ArgonBox
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <ArgonBox
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center">
                    <ArgonTypography variant="h6">
                      Conversations
                    </ArgonTypography>
                    <IconButton onClick={fetchConversations} size="small">
                      <RefreshIcon />
                    </IconButton>
                  </ArgonBox>

                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      variant="fullWidth"
                      sx={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: 'transparent'
                        }
                      }}>
                      <Tab label="all" />
                      <Tab
                        label={
                          <Badge
                            color="error"
                            badgeContent={
                              conversations.filter((c) => !c.isRead).length
                            }
                            max={99}
                            sx={{
                              '& .MuiBadge-badge': {
                                marginTop: '6px',
                                fontSize: '0.55rem',
                                height: '14px',
                                minWidth: '14px',
                                padding: '0 2px',
                                top: -2,
                                right: -6
                              }
                            }}>
                            unread
                          </Badge>
                        }
                      />
                      <Tab label="read" />
                    </Tabs>
                  </Box>

                  {loading && conversations.length === 0 ? (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <List sx={{ overflow: 'auto', flexGrow: 1 }}>
                      {conversations.length === 0 ? (
                        <Box sx={{ p: 3, textAlign: 'center' }}>
                          <ArgonTypography
                            variant="body2"
                            color="text.secondary">
                            No conversations found
                          </ArgonTypography>
                        </Box>
                      ) : (
                        conversations.map((conversation) => (
                          <React.Fragment key={conversation._id}>
                            <StyledListItem
                              button
                              selected={
                                selectedConversation?._id === conversation._id
                              }
                              onClick={() =>
                                handleSelectConversation(conversation)
                              }>
                              <ListItemAvatar>
                                <Badge
                                  color="error"
                                  variant="dot"
                                  invisible={conversation.isRead}>
                                  <Avatar sx={{ marginLeft: '4px' }}>
                                    {getChannelIcon(conversation.channel)}
                                  </Avatar>
                                </Badge>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <ArgonTypography
                                    variant="regular"
                                    color="secondary"
                                    fontWeight={
                                      !conversation.isRead ? 'bold' : 'normal'
                                    }>
                                    {conversation.recipient?.name ||
                                      conversation.recipient?.email ||
                                      conversation.recipient?.phone ||
                                      conversation.messages[
                                        conversation.messages?.length - 1
                                      ]?.sender?.name ||
                                      conversation.messages[
                                        conversation.messages?.length - 1
                                      ]?.sender?.email ||
                                      'Unknown'}
                                  </ArgonTypography>
                                }
                                secondary={
                                  <>
                                    <ArgonTypography
                                      variant="regular"
                                      color="dark"
                                      fontWeight={
                                        !conversation.isRead ? 'bold' : 'normal'
                                      }
                                      noWrap>
                                      {conversation?.subject ||
                                        conversation?.messages[
                                          conversation?.messages?.length - 1
                                        ]?.content ||
                                        'No message'}
                                    </ArgonTypography>
                                    <br />
                                    <ArgonTypography
                                      variant="caption"
                                      color="text.secondary">
                                      {formatDate(conversation.updatedAt)}
                                    </ArgonTypography>
                                  </>
                                }
                              />
                            </StyledListItem>
                            {/* <Divider variant="inset" component="li" /> */}
                            <HorizontalDivider color="#f0f0f0" mt={0} mb={0} />
                          </React.Fragment>
                        ))
                      )}
                    </List>
                  )}
                </ArgonBox>
              </Allotment.Pane>

              {/* Conversation Details and Response Editor */}
              <Allotment.Pane style={{ height: '100%' }}>
                <Allotment vertical style={{ height: '100%' }}>
                  {/* Conversation Details */}
                  <Allotment.Pane
                  // style={{
                  //   overflow: 'auto',
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  //   height: '100%'
                  // }}
                  >
                    {selectedConversation ? (
                      <>
                        {/* Conversation Header */}
                        <ArgonBox p={2} bgcolor="grey.100">
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <Avatar>
                                {getChannelIcon(selectedConversation.channel)}
                              </Avatar>
                            </Grid>
                            <Grid item xs>
                              <ArgonTypography variant="h6">
                                {selectedConversation.sender?.name ||
                                  selectedConversation.sender?.email ||
                                  selectedConversation.messages[0]?.sender
                                    ?.name ||
                                  selectedConversation.messages[0]?.sender
                                    ?.email ||
                                  'Unknown'}
                              </ArgonTypography>
                              <ArgonTypography
                                variant="body2"
                                color="text.secondary">
                                {selectedConversation.sender?.email ||
                                  selectedConversation.sender?.phone ||
                                  ''}
                              </ArgonTypography>
                            </Grid>
                            <Grid item>
                              <ArgonTypography
                                variant="caption"
                                color="text.secondary">
                                Kanal:{' '}
                                {selectedConversation.channel || 'Unknown'}
                              </ArgonTypography>
                            </Grid>
                          </Grid>

                          {/* EventInstance Information */}
                          {eventInstanceLoading ? (
                            <ArgonBox
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 2
                              }}>
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              <ArgonTypography variant="body2">
                                Lade Event-Informationen...
                              </ArgonTypography>
                            </ArgonBox>
                          ) : eventInstance ? (
                            <ArgonBox mt={2} ml={1}>
                              <Chip
                                icon={<EventIcon />}
                                label={
                                  <ArgonTypography
                                    variant="caption"
                                    color="dark">
                                    Event:{' '}
                                    {eventInstance?.event?.name +
                                      ' - ' +
                                      (eventInstance?.name ||
                                        selectData.eventInstanceTypes.find(
                                          (type) =>
                                            type.value === eventInstance.type
                                        )?.label)}
                                  </ArgonTypography>
                                }
                                variant="outlined"
                                size="small"
                                sx={{ mr: 1 }}
                              />
                              {eventInstance.startDate && (
                                <Chip
                                  label={
                                    <ArgonTypography
                                      variant="caption"
                                      color="dark">
                                      Date:{' '}
                                      {new Date(
                                        eventInstance.startDate
                                      ).toLocaleDateString('de-DE')}
                                    </ArgonTypography>
                                  }
                                  variant="outlined"
                                  size="small"
                                  sx={{ mr: 1 }}
                                />
                              )}
                              {eventInstance.location?.city && (
                                <Chip
                                  label={
                                    <ArgonTypography
                                      variant="caption"
                                      color="dark">
                                      City: {eventInstance.location.city}
                                    </ArgonTypography>
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            </ArgonBox>
                          ) : null}
                        </ArgonBox>

                        {/* Messages - Ausgelagert in ConversationMessages-Komponente */}
                        <Box
                          sx={{
                            flexGrow: 1,
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100% - 120px)'
                          }}>
                          <ConversationMessages
                            conversationDetails={conversationDetails}
                            loading={loading}
                          />
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}>
                        <ArgonTypography variant="body1" color="text.secondary">
                          Choose a conversation to see the details
                        </ArgonTypography>
                      </Box>
                    )}
                  </Allotment.Pane>

                  {/* Response Editor */}
                  <Allotment.Pane
                    ref={paneRef}
                    preferredSize={300}
                    minSize={150}
                    style={{ overflow: 'auto', height: '100%' }}>
                    {selectedConversation && (
                      <ArgonBox
                        p={0}
                        bgcolor="secondary"
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                        <QuillEditorFlat
                          ref={editorRef}
                          value={responseContent}
                          onChange={setResponseContent}
                          disabled={!selectedConversation || aiQueryLoading}
                          placeholder={
                            responseType === 'external'
                              ? 'Compose response...'
                              : 'Write internal note...'
                          }
                          height={paneHeight - 20}
                          uniqueKey="conversation-response-editor"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'list',
                            'bullet',
                            'link',
                            'image',
                            'clean'
                          ]}
                        />
                        {/* </Box> */}
                      </ArgonBox>
                    )}
                  </Allotment.Pane>
                  <Allotment.Pane
                    // preferredSize={44}
                    minSize={80}
                    maxSize={80}
                    style={{ overflow: 'auto', height: '100%' }}>
                    <ArgonBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      p={2}>
                      <ArgonButton
                        color="secondary"
                        variant="outlined"
                        disabled={
                          aiQueryLoading ||
                          !eventInstance?.aiAssistantApiKey ||
                          !eventInstance?.aiAssistantClientId
                        }
                        loading={aiQueryLoading}
                        loadingColor="secondary"
                        endIcon={!aiQueryLoading ? <AutoAwesomeIcon /> : null}
                        onClick={handleComposeMessageWithAiButtonPressed}>
                        Compose with AI
                      </ArgonButton>
                      <ArgonButton
                        color="success"
                        variant="gradient"
                        onClick={handleSendResponse}
                        disabled={sendingResponse || !responseContent.trim()}
                        loading={sendingResponse}>
                        {responseType === 'external'
                          ? 'Send response'
                          : 'Save note'}
                      </ArgonButton>
                    </ArgonBox>
                  </Allotment.Pane>
                </Allotment>
              </Allotment.Pane>
            </Allotment>
          </Card>
          {/* Just for testing purposes */}
          {/* <StreamingQueryComponent /> */}
        </Grid>
      </Grid>
    </ArgonBox>
  );
}

Conversations.propTypes = {
  organizationId: PropTypes.string,
  onConversationSelected: PropTypes.func,
  selectedEventInstance: PropTypes.object,
  eventInstanceLoading: PropTypes.bool
};

export default Conversations;
