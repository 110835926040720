import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as BaseApolloProvider,
  HttpLink
} from '@apollo/client';
import * as Realm from 'realm-web';
import UserPool from '../services/cognito/userPool';

import PageLayout from 'Layout/PageLayout';
import Loader from 'Loader';

// import borders from 'assets/theme-dark/base/borders';
// import typography from 'assets/theme-dark/base/typography';
// import colors from 'assets/theme-dark/base/colors';
// import boxShadows from 'assets/theme-dark/base/boxShadows';

// // // Argon Dashboard 2 PRO MUI helper functions
// import linearGradient from 'assets/theme-dark/functions/linearGradient';
// import pxToRem from 'assets/theme-dark/functions/pxToRem';

// const { borderRadius } = borders;
// const { fontWeightMedium, fontWeightBold, size } = typography;
// const { gradients, transparent, white, background } = colors;
// const { buttonBoxShadow } = boxShadows;

import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Argon Dashboard 2 PRO MUI themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// My own CSS additions
import 'assets/css/custom.module.css';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useArgonController } from 'context';

// Realm App ID aus der Umgebungsvariable
const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

// Erstellen einer neuen Realm App-Instanz
const realmApp = new Realm.App(REALM_APP_ID);

// Kontext für den Apollo-Client und Authentifizierungsstatus
export const ApolloContext = createContext(null);

// Hook zum Zugriff auf den Apollo-Kontext
export const useApollo = () => useContext(ApolloContext);

/**
 * Apollo Provider Komponente, die den Apollo Client für alle Unterkomponenten bereitstellt
 * und die Authentifizierung mit Realm und Cognito verwaltet.
 */
export const ApolloProvider = ({ children }) => {
  const [controller, dispatch] = useArgonController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    darkSidenav,
    darkMode
  } = controller;

  const [client, setClient] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // Funktion zum Abrufen des ID-Tokens von Cognito
  const getIdToken = () => {
    return new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            console.error("Error fetching user's id-token", err);
            reject(err);
          } else {
            resolve(session.idToken.jwtToken);
          }
        });
      } else {
        console.log('User currently not logged in to Cognito User Pool.');
        resolve(null);
      }
    });
  };

  // Hilfsfunktion zum Bereinigen von Session-Daten
  const cleanupSessionData = () => {
    console.log('Mobile Debug: Cleaning up session data');
    try {
      // Versuchen, alle Realm-bezogenen Daten aus dem localStorage zu entfernen
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (
          key &&
          (key.includes('realm') ||
            key.includes('Realm') ||
            key.includes('mongodb'))
        ) {
          keysToRemove.push(key);
        }
      }

      keysToRemove.forEach((key) => {
        console.log('Mobile Debug: Removing localStorage key:', key);
        localStorage.removeItem(key);
      });

      // Auch den freshLoginDetected-Flag entfernen
      localStorage.removeItem('freshLoginDetected');

      // Versuchen, alle Cookies zu löschen, die mit Realm zusammenhängen könnten
      document.cookie.split(';').forEach((cookie) => {
        const [name] = cookie.trim().split('=');
        if (
          name &&
          (name.includes('realm') ||
            name.includes('Realm') ||
            name.includes('mongodb'))
        ) {
          console.log('Mobile Debug: Removing cookie:', name);
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      });

      console.log('Mobile Debug: Session data cleanup completed');
    } catch (error) {
      console.error('Mobile Debug: Error during session data cleanup:', error);
      // Fehler beim Bereinigen ignorieren und fortfahren
    }
  };

  // Funktion zum Anmelden bei Realm mit JWT oder anonym
  const loginToRealm = async () => {
    console.log('Mobile Debug: loginToRealm called');
    try {
      // Prüfen, ob ein Benutzer bei Cognito angemeldet ist
      console.log('Mobile Debug: Getting ID token');
      const idToken = await getIdToken();
      console.log(
        'Mobile Debug: ID token received:',
        idToken ? 'Valid token' : 'No token'
      );

      if (idToken) {
        // Anmelden mit JWT, wenn ein ID-Token vorhanden ist
        console.log('Mobile Debug: Logging in to Realm with JWT');
        try {
          const user = await realmApp.logIn(Realm.Credentials.jwt(idToken));
          console.log('Mobile Debug: JWT login successful');
          setIsAuthenticated(true);
          setIsAnonymous(false);
          return user;
        } catch (jwtError) {
          console.error('Mobile Debug: JWT login failed:', jwtError);
          throw jwtError; // Weiterleiten an den äußeren catch-Block
        }
      } else {
        // Anonyme Anmeldung, wenn kein ID-Token vorhanden ist
        console.log('Mobile Debug: Logging in to Realm anonymously');
        try {
          const user = await realmApp.logIn(Realm.Credentials.anonymous());
          console.log('Mobile Debug: Anonymous login successful');
          setIsAuthenticated(true);
          setIsAnonymous(true);
          return user;
        } catch (anonError) {
          console.error('Mobile Debug: Anonymous login failed:', anonError);

          // Wenn der Fehler "token contains an invalid number of segments" enthält,
          // Session-Daten bereinigen und erneut versuchen
          if (
            anonError.message &&
            anonError.message.includes('invalid number of segments')
          ) {
            console.log(
              'Mobile Debug: Detected invalid token error, cleaning up session data'
            );
            cleanupSessionData();

            // Erneut versuchen, sich anonym anzumelden
            console.log('Mobile Debug: Retrying anonymous login after cleanup');
            try {
              const user = await realmApp.logIn(Realm.Credentials.anonymous());
              console.log('Mobile Debug: Retry anonymous login successful');
              setIsAuthenticated(true);
              setIsAnonymous(true);
              return user;
            } catch (retryError) {
              console.error(
                'Mobile Debug: Retry anonymous login failed:',
                retryError
              );
              throw retryError;
            }
          }

          throw anonError; // Weiterleiten an den äußeren catch-Block
        }
      }
    } catch (error) {
      console.error('Mobile Debug: Error logging in to Realm:', error);

      // Wenn wir hier ankommen und der Fehler mit Token zusammenhängt, Session-Daten bereinigen
      if (error.message && error.message.includes('token')) {
        console.log(
          'Mobile Debug: Detected token-related error, cleaning up session data'
        );
        cleanupSessionData();
      }

      // Fallback auf anonyme Anmeldung bei Fehlern
      try {
        console.log('Mobile Debug: Falling back to anonymous login');
        const user = await realmApp.logIn(Realm.Credentials.anonymous());
        console.log('Mobile Debug: Fallback anonymous login successful');
        setIsAuthenticated(true);
        setIsAnonymous(true);
        return user;
      } catch (fallbackError) {
        console.error(
          'Mobile Debug: Error with fallback anonymous login:',
          fallbackError
        );

        // Letzter Versuch: Alles bereinigen und erneut versuchen
        console.log('Mobile Debug: Final attempt: cleaning all session data');
        cleanupSessionData();

        try {
          console.log('Mobile Debug: Final anonymous login attempt');
          const user = await realmApp.logIn(Realm.Credentials.anonymous());
          console.log('Mobile Debug: Final anonymous login successful');
          setIsAuthenticated(true);
          setIsAnonymous(true);
          return user;
        } catch (finalError) {
          console.error(
            'Mobile Debug: Final anonymous login failed:',
            finalError
          );
          setIsAuthenticated(false);
          setIsAnonymous(true);
          throw finalError;
        }
      }
    }
  };

  // Hilfsfunktion zur Weiterleitung zur Login-Seite
  const redirectToLogin = () => {
    console.log('Mobile Debug: Redirecting to login page');
    // Weiterleitung zur Login-Seite
    window.location.href = '/login';
  };

  // Funktion zum Abrufen eines gültigen Access Tokens
  const getValidAccessToken = async () => {
    console.log('Mobile Debug: getValidAccessToken called');
    // Prüfen, ob ein frischer Login erkannt wurde
    let freshLogin;
    try {
      freshLogin = localStorage.getItem('freshLoginDetected');
      console.log('Mobile Debug: freshLogin from localStorage:', freshLogin);
    } catch (error) {
      console.error('Mobile Debug: Error accessing localStorage:', error);
      freshLogin = null;
    }

    if (realmApp.currentUser && !freshLogin) {
      // Wenn ein Benutzer bereits angemeldet ist, Token aktualisieren
      try {
        console.log('Mobile Debug: Refreshing token for existing user');
        await realmApp.currentUser.refreshCustomData();
        console.log('Mobile Debug: Token refreshed successfully');
        return realmApp.currentUser.accessToken;
      } catch (error) {
        console.error('Mobile Debug: Error refreshing token:', error);

        // Prüfen, ob der Refresh Token abgelaufen ist
        const isRefreshTokenExpired =
          error.message &&
          (error.message.includes('expired') ||
            error.message.includes('invalid refresh token') ||
            error.message.includes('not authorized'));

        if (isRefreshTokenExpired) {
          console.log(
            'Mobile Debug: Refresh token expired, cleaning up and redirecting to login'
          );

          // Session-Daten bereinigen
          cleanupSessionData();

          // Abmelden des aktuellen Benutzers
          if (realmApp.currentUser) {
            try {
              await realmApp.currentUser.logOut();
              console.log(
                'Mobile Debug: User logged out due to expired refresh token'
              );
            } catch (logoutError) {
              console.error(
                'Mobile Debug: Error logging out user:',
                logoutError
              );
            }
          }

          // Anonym anmelden
          try {
            const user = await realmApp.logIn(Realm.Credentials.anonymous());
            console.log(
              'Mobile Debug: Anonymous login successful after token expiration'
            );
            setIsAuthenticated(false);
            setIsAnonymous(true);

            // Weiterleitung zur Login-Seite
            setTimeout(redirectToLogin, 100);

            return user.accessToken;
          } catch (anonError) {
            console.error(
              'Mobile Debug: Error with anonymous login after token expiration:',
              anonError
            );
            // Trotzdem zur Login-Seite weiterleiten
            setTimeout(redirectToLogin, 100);
            throw anonError;
          }
        } else {
          // Bei anderen Fehlern neu anmelden (bestehende Logik)
          console.log(
            'Mobile Debug: Attempting to login to Realm after refresh error'
          );
          const user = await loginToRealm();
          console.log('Mobile Debug: Login successful after refresh error');
          return user.accessToken;
        }
      }
    } else {
      // Wenn ein frischer Login erkannt wurde, vorherigen Benutzer abmelden
      if (freshLogin) {
        console.log(
          'Mobile Debug: Fresh login detected, logging out previous user'
        );
        try {
          localStorage.removeItem('freshLoginDetected');
          console.log(
            'Mobile Debug: Removed freshLoginDetected from localStorage'
          );
        } catch (error) {
          console.error(
            'Mobile Debug: Error removing from localStorage:',
            error
          );
        }

        if (realmApp.currentUser) {
          try {
            console.log('Mobile Debug: Logging out current user');
            await realmApp.currentUser.logOut();
            console.log('Mobile Debug: User logged out successfully');
          } catch (error) {
            console.error(
              'Mobile Debug: Error logging out previous user:',
              error
            );
          }
        }
      }

      // Neu anmelden
      console.log('Mobile Debug: Attempting to login to Realm');
      const user = await loginToRealm();
      console.log('Mobile Debug: Login successful, returning access token');
      return user.accessToken;
    }
  };

  // Funktion zum Erstellen eines neuen Apollo Clients
  const createApolloClient = () => {
    console.log('Mobile Debug: Creating Apollo client configuration');
    return new ApolloClient({
      link: new HttpLink({
        uri: `https://eu-central-1.aws.services.cloud.mongodb.com/api/client/v2.0/app/${REALM_APP_ID}/graphql`,
        // Benutzerdefinierter Fetch-Handler für Apollo-Anfragen
        fetch: async (uri, options) => {
          console.log('Mobile Debug: Apollo fetch handler called');
          try {
            // Gültigen Access Token abrufen
            console.log('Mobile Debug: Getting valid access token for request');
            const accessToken = await getValidAccessToken();
            console.log('Mobile Debug: Access token received successfully');

            // Authorization-Header mit Access Token hinzufügen
            options.headers.Authorization = `Bearer ${accessToken}`;
            console.log('Mobile Debug: Added authorization header');

            // Anfrage ausführen
            console.log('Mobile Debug: Executing fetch request to:', uri);
            const startTime = Date.now();
            try {
              const response = await fetch(uri, options);
              const endTime = Date.now();
              console.log(
                `Mobile Debug: Fetch completed in ${
                  endTime - startTime
                }ms with status:`,
                response.status
              );
              return response;
            } catch (fetchError) {
              console.error('Mobile Debug: Network fetch error:', fetchError);
              throw fetchError;
            }
          } catch (error) {
            console.error(
              'Mobile Debug: Error in Apollo fetch handler:',
              error
            );
            throw error;
          }
        }
      }),
      cache: new InMemoryCache()
    });
  };

  // Initialisierung des Apollo Clients beim ersten Laden
  useEffect(() => {
    console.log('Mobile Debug: Apollo initialization useEffect triggered');
    const initializeApollo = async () => {
      console.log('Mobile Debug: initializeApollo started');
      setIsLoading(true);
      try {
        // Bei Realm anmelden
        console.log('Mobile Debug: Calling loginToRealm from initializeApollo');
        await loginToRealm();
        console.log('Mobile Debug: loginToRealm completed successfully');

        // Apollo Client erstellen
        console.log('Mobile Debug: Creating Apollo client');
        const newClient = createApolloClient();
        console.log('Mobile Debug: Apollo client created successfully');
        setClient(newClient);
        console.log('Mobile Debug: Client state updated');
      } catch (error) {
        console.error('Mobile Debug: Error initializing Apollo client:', error);
      } finally {
        console.log('Mobile Debug: Setting isLoading to false');
        setIsLoading(false);
      }
    };

    initializeApollo();
  }, []);

  // Listener für Änderungen am Authentifizierungsstatus
  useEffect(() => {
    console.log(
      'Mobile Debug: Auth change listener useEffect triggered, isAnonymous:',
      isAnonymous
    );

    const handleAuthChange = async () => {
      console.log('Mobile Debug: handleAuthChange called');
      // Prüfen, ob ein Benutzer bei Cognito angemeldet ist
      const user = UserPool.getCurrentUser();
      console.log(
        'Mobile Debug: Current Cognito user:',
        user ? 'exists' : 'none'
      );

      if (user) {
        try {
          // Session abrufen, um zu prüfen, ob der Benutzer gültig ist
          console.log('Mobile Debug: Getting Cognito session');
          await new Promise((resolve, reject) => {
            user.getSession((err, session) => {
              if (err) {
                console.error(
                  'Mobile Debug: Error getting Cognito session:',
                  err
                );
                reject(err);
              } else {
                console.log('Mobile Debug: Cognito session valid');
                resolve(session);
              }
            });
          });

          // Wenn der Benutzer anonym angemeldet ist, aber jetzt bei Cognito angemeldet ist,
          // neu anmelden mit JWT
          if (isAnonymous) {
            console.log(
              'Mobile Debug: User is anonymous but has Cognito session, updating client'
            );
            try {
              localStorage.setItem('freshLoginDetected', 'true');
              console.log(
                'Mobile Debug: Set freshLoginDetected in localStorage'
              );
              const newClient = createApolloClient();
              console.log('Mobile Debug: Created new Apollo client');
              setClient(newClient);
              console.log('Mobile Debug: Updated client state');
            } catch (storageError) {
              console.error(
                'Mobile Debug: Error with localStorage in auth change:',
                storageError
              );
            }
          }
        } catch (error) {
          console.error('Mobile Debug: Error checking Cognito session:', error);
        }
      } else if (!isAnonymous) {
        // Wenn der Benutzer nicht bei Cognito angemeldet ist, aber nicht anonym angemeldet ist,
        // neu anmelden als anonymer Benutzer
        console.log(
          'Mobile Debug: No Cognito user but not anonymous, updating client'
        );
        try {
          localStorage.setItem('freshLoginDetected', 'true');
          console.log('Mobile Debug: Set freshLoginDetected in localStorage');
          const newClient = createApolloClient();
          console.log('Mobile Debug: Created new Apollo client');
          setClient(newClient);
          console.log('Mobile Debug: Updated client state');
        } catch (storageError) {
          console.error(
            'Mobile Debug: Error with localStorage in auth change:',
            storageError
          );
        }
      }
    };

    // Authentifizierungsstatus alle 15 Minuten prüfen
    console.log('Mobile Debug: Setting up auth check interval (15 min)');
    const interval = setInterval(handleAuthChange, 15 * 60 * 1000);

    // Auch sofort beim Mounten prüfen
    handleAuthChange();

    // Aufräumen beim Unmount
    return () => {
      console.log('Mobile Debug: Clearing auth check interval');
      clearInterval(interval);
    };
  }, [isAnonymous]);

  // Funktion zum Abmelden des aktuellen Benutzers
  const logout = async () => {
    try {
      if (realmApp.currentUser) {
        console.log('Logging out current Realm user');
        await realmApp.currentUser.logOut();
        setIsAuthenticated(false);
        setIsAnonymous(true);

        // Neuen Client mit anonymer Anmeldung erstellen
        await loginToRealm();
        const newClient = createApolloClient();
        setClient(newClient);

        return true;
      }
      return false;
    } catch (error) {
      console.error('Error logging out from Realm:', error);
      return false;
    }
  };

  // Funktion zum expliziten Anmelden eines Benutzers
  const login = async (idToken = null) => {
    try {
      // Wenn ein Benutzer bereits angemeldet ist, zuerst abmelden
      if (realmApp.currentUser) {
        await realmApp.currentUser.logOut();
      }

      let user;

      if (idToken) {
        // Mit JWT anmelden
        user = await realmApp.logIn(Realm.Credentials.jwt(idToken));
        setIsAuthenticated(true);
        setIsAnonymous(false);
      } else {
        // Anonym anmelden
        user = await realmApp.logIn(Realm.Credentials.anonymous());
        setIsAuthenticated(true);
        setIsAnonymous(true);
      }

      // Neuen Client mit der neuen Anmeldung erstellen
      const newClient = createApolloClient();
      setClient(newClient);

      return user;
    } catch (error) {
      console.error('Error logging in to Realm:', error);
      throw error;
    }
  };

  // Kontext-Wert mit Apollo Client und Authentifizierungsstatus
  const contextValue = {
    client,
    isAuthenticated,
    isAnonymous,
    isLoading,
    realmApp,
    loginToRealm,
    getValidAccessToken,
    logout,
    login
  };

  // Wenn der Client noch nicht initialisiert ist, Ladeindikator anzeigen
  if (!client || isLoading) {
    console.log(
      'Mobile Debug: Rendering loading state, client:',
      !!client,
      'isLoading:',
      isLoading
    );
    // Verbesserte Ladekomponente mit mehr Informationen
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <div
          style={{
            padding: '20px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}>
          {/* <div>Initializing application...</div>
        <div style={{ marginTop: '10px', fontSize: '14px', color: '#666' }}>
          {!client ? 'Setting up connection...' : 'Finalizing setup...'}
        </div> */}
          <Loader />
          <div
            id="mobile-debug-info"
            style={{
              marginTop: '20px',
              fontSize: '12px',
              color: '#999',
              maxWidth: '80%',
              wordBreak: 'break-word',
              display: 'none' // Versteckt auf normalen Geräten, kann für Debugging aktiviert werden
            }}>
            App ID: {REALM_APP_ID || 'Not available'}
            <br />
            Authentication: {isAuthenticated ? 'Yes' : 'No'}
            <br />
            Anonymous: {isAnonymous ? 'Yes' : 'No'}
          </div>
        </div>
      </ThemeProvider>
    );
  }

  // Apollo Provider mit dem erstellten Client rendern
  return (
    <ApolloContext.Provider value={contextValue}>
      <BaseApolloProvider client={client}>{children}</BaseApolloProvider>
    </ApolloContext.Provider>
  );
};

export default ApolloProvider;
