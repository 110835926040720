import { EVENT_FRAGMENT } from 'GraphQL/Events/fragments';

const { gql } = require('@apollo/client');

export const EVENTINSTANCE_FRAGMENT = gql`
  ${EVENT_FRAGMENT}
  fragment EventInstanceFragment on EventInstance {
    _id
    creationDate
    slug
    event {
      ...EventFragment
    }
    registrationDeadline
    startDate
    endDate
    timeZone

    description

    scheduleAvailable
    scheduleDetails
    scheduleAttachments {
      _id
      description
      fileName
      fileType
      url
    }

    organizationId
    type
    isOpenRegistration
    name
    maxParticipants
    registrationFee
    isVirtualEvent
    divisions {
      _id
      name
      description
      maxParticipants
      forIndividuals
      forTeams
      forUnderage
      teamSize
      minAge
      maxAge
      registrationFee
      advancingAthletesNumber
      advancingAthletesPercentage
      assignedJudges
      sequence
      stripePriceId
    }
    location {
      address1
      address2
      zip
      city
      country
      state
    }
    stripeProductId
    stripePriceId
    stripeTaxRateId
    customRequiredFields {
      _id
      name
      label
      type
      required
      belongsTo # "participant" or "registration" => where to ask for this field
      options {
        value
        label
      }
    }
    # invitationsDefaults {
    #   messageSubject
    #   messageBody
    #   messageTemplateId
    #   inviteExpiryDays
    #   messageSenderName
    #   messageSenderEmail
    # }

    autoInvitationData {
      fromEventInstance
      messageTemplateId
      messageSubject
      messageBody
      senderEmail
      senderName

      invitationExpiryDays

      invitationsPerDivision {
        divisionFromId
        divisionFromName
        divisionToId
        divisionToName
        inviteCount
        invitationMethod
        invitedRegistrations {
          registrationId
          rank
          owner
          ownerName
          ownerEmail
        }
        unconsideredRegistrations {
          registrationId
          rank
          owner
          ownerName
          ownerEmail
        }
      }
    }
    spectatorTicketsSold
    spectatorTicketsOffered {
      _id
      description
      validDate
      initialQuantity
      price
      stripeProductId
      stripePriceId
      pax
      venueOpens
      venueCloses
    }
    spectatorTicketsLimit
    spectatorTicketSalesStartDate
    spectatorTicketSalesEndDate

    volunteerRegistrationEnabled
    volunteerEnrollmentStartDate
    volunteerEnrollmentEndDate
    volunteerPositionsOffered {
      _id
      name
      description
      requirements
      requestExperience
      compensation
      requiredCapacity
      startDate
      endDate
      selectDays
      requestAdditionalInfo
      additionalInfoQuestion
      fullAddressRequired
      overnightAccomodationOffered
    }
    aiAssistantEnabled
    aiAssistantClientId
    aiAssistantApiKey
  }
`;
